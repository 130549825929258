@import '~antd/lib/avatar/style/index';

.avatarPlaceholder {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.avatarOrgIcon {
    background-color: @cws-green-60  !important;
    color: @cws-neutral-10  ! important;
}

.syt-antd-avatar {
    background: @cws-neutral-10;
    color: @cws-green-60;
}
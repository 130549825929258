@import '~antd/lib/button/style/index';

.primaryButton:extend(.ant-btn-primary) {
    color: @cws-neutral-00;
    background-color: @cws-green-60;
    border-color: @cws-green-60;
    border-radius: 4px;

    &:hover,
    &:focus {
        color: @cws-neutral-00;
        background-color: @cws-green-50;
        border-color: @cws-green-50;
    }
    &:focus {
        box-shadow: 0px 0px 0px 3px rgba(@cws-green-50, 0.25);
    }
    &:active {
        background-color: @cws-green-70;
        border-color: @cws-green-70;
    }
    &:disabled {
        background: @cws-neutral-10;
        border: 1px solid @cws-neutral-20;
        box-sizing: border-box;
    }
}

.successButton:extend(.ant-btn-primary) {
  color: @cws-neutral-00;
  background-color: @base-success-color;
  border-color: @base-success-color;

  &:hover,
  &:focus {
    color: @cws-neutral-00;
    background-color: @cws-green-50;
    border-color: @cws-green-50;
  }
}

.warnButton:extend(.ant-btn-danger) {
  color: @cws-neutral-00;
  background-color: @base-warn-color;
  border-color: @base-warn-color;

  &:hover,
  &:focus {
    color: @cws-neutral-00;
    background-color: @base-yellow-2;
    border-color: @base-yellow-2;
  }
}

.outlineButton:extend(.ant-btn-primary) {
  color: @cws-neutral-100;
  background-color: @cws-neutral-00;
  border-color: @cws-neutral-30;

  &:hover{
    color: @cws-neutral-100;
    background-color: @cws-neutral-10;
    border-color: @cws-neutral-30;
  }
  &:focus {
    color: @cws-neutral-100;
    background-color: @cws-neutral-10;
    border-color: @cws-neutral-30;
    box-shadow: 0px 0px 0px 3px rgba(@cws-blue-50, 0.25);
  }
  &:active {
    color: @cws-neutral-100;
    background-color: @cws-neutral-20;
    border-color: @cws-neutral-30;
  }
}
.overlayButton:extend(.ant-btn-primary) {
  color: @cws-neutral-100;
  background-color: @cws-neutral-00;
  border-color: @cws-neutral-00;
  box-shadow: 0px 2px 4px rgba(92, 101, 117, 0.3), 0px 0px 1px rgba(92, 101, 117, 0.47);
  &:hover{
    color: @cws-neutral-100;
    background-color: @cws-neutral-10;
    border-color: @cws-neutral-10;
    box-shadow: 0px 2px 4px rgba(92, 101, 117, 0.3), 0px 0px 1px rgba(92, 101, 117, 0.47);
  }
  &:focus {
    color: @cws-neutral-100;
    background-color: @cws-neutral-10;
    border-color: @cws-neutral-10;
    box-shadow: 0px 2px 4px rgba(92, 101, 117, 0.3), 0px 0px 1px rgba(92, 101, 117, 0.47), 0px 0px 0px 3px rgba(@cws-blue-50, 0.25);
  }
  &:active {
    color: @cws-neutral-100;
    background-color: @cws-neutral-20;
    border-color: @cws-neutral-20;
    box-shadow: 0px 2px 4px rgba(92, 101, 117, 0.3), 0px 0px 1px rgba(92, 101, 117, 0.47);
  }
}

.dashedButton:extend(.ant-btn-primary) {
    color: @cws-neutral-00;
    background-color: transparent;
    border: 1px dashed @cws-neutral-00;

    &:hover,
    &:focus {
        color: @cws-green-50;
        background-color: transparent;
        border: 1px dashed @cws-green-50;
    }
    &:active {
        color: @cws-green-70;
        background-color: transparent;
        border: 1px dashed @cws-green-70;
    }
    &:disabled {
        color: @cws-neutral-20;
        background-color: @cws-neutral-00;
        border: 1px solid @cws-neutral-20;
        box-sizing: border-box;
    }
}

.dangerButton:extend(.ant-btn-primary) {
  color: @cws-neutral-00;
  background-color: @cws-red-60;
  border-color: @cws-red-60;

  &:hover,
  &:focus {
      color: @cws-neutral-00;
      background-color: @cws-red-50;
      border-color: @cws-red-50;
  }
  &:focus {
      box-shadow: 0px 0px 0px 3px rgba(@cws-blue-50, 0.25);
  }
  &:active {
      background-color: @cws-red-70;
      border-color: @cws-red-70;
  }
  &:disabled {
      background: @cws-neutral-10;
      border: 1px solid @cws-neutral-20;
      box-sizing: border-box;
  }
}

.defaultButton:extend(.ant-btn-primary) {
  color: @cws-neutral-100;
  background-color: @cws-neutral-10;
  border-color: @cws-neutral-10;

  &:hover {
      color: @cws-neutral-100;
      background-color: @cws-neutral-20;
      border-color: @cws-neutral-20;
  }
  &:focus {
      color: @cws-neutral-100;
      background-color: @cws-neutral-10;
      border-color: @cws-neutral-10;
      box-shadow: 0px 0px 0px 3px rgba(@cws-blue-50, 0.25);
  }
  &:active {
      background-color: @cws-neutral-30;
      border-color: @cws-neutral-30;
  }
  &:disabled {
      background: @cws-neutral-10;
      border: 1px solid @cws-neutral-20;
      box-sizing: border-box;
  }
}
.blueLinkButton:extend(.ant-btn-primary) {
    color: @cws-blue-60;
    background-color: transparent;
    border-color: transparent;

    &:hover,
    &:focus {
        color: @cws-blue-50;
        background-color: transparent;
        border-color: transparent;
    }
    &:active {
        color: @cws-blue-70;
        background-color: transparent;
        border-color: transparent;
    }
    &:disabled {
        color: @cws-neutral-30;
        background: transparent;
        border: transparent;
    }
}
.greenLinkButton:extend(.ant-btn-primary) {
    color: @cws-green-60;
    background-color: transparent;
    border-color: transparent;

    &:hover,
    &:focus {
        color: @cws-green-50;
        background-color: transparent;
        border-color: transparent;
    }
    &:active {
        color: @cws-green-70;
        background-color: transparent;
        border-color: transparent;
    }
    &:disabled {
        color: @cws-neutral-30;
        background: transparent;
        border: transparent;
    }
}

.text-default {
  /*color: @base-black;*/
  font-family: @base-font-family;
  font-style: @base-font-style;
  font-weight: @base-font-weight;
}

.bold {
  /*color: @base-black;*/
  font-family: @base-font-family-bold;
  font-style: @base-font-bold-style;
  font-weight: @base-font-bold-weight;
}

.font-adjust-mixin(@font-size, @line-height) {
  font-size: @font-size;
  line-height: @line-height;
}

.text-mixin(@name, @font-size, @line-height, @bold-font-size, @bold-line-height) {
  .@{name} {
    .text-default();
    .font-adjust-mixin(@font-size, @line-height);
  }

  .@{name} .bold {
    .font-adjust-mixin(@bold-font-size, @bold-line-height);
    .bold();
  }
}

.text-mixin(baseText, 14px, 17px, 14px, 17px);
.text-mixin(baseTextHeader, 28px, 33px, 27.7941px, 33px);
.text-mixin(baseTextTitle, 16px, 19px, 16px, 19px);

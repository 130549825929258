@import '~antd/lib/switch/style/index';

.cwsSwitch:extend(.syt-antd-switch) {
  background-color: @cws-neutral-40;
  &:focus {
    box-shadow: none;
  }
  &:disabled {
    opacity: 0.4;
  }
}
.cwsSwitchChecked:extend(.syt-antd-switch-checked) {
  background-color: @cws-blue-60;
  &:focus {
    box-shadow: none;
  }
  &:disabled {
    opacity: 0.4;
  }
}

@import '~antd/lib/menu/style/index';

.sidebarLayout {
	min-height: 100vh;
	overflow: hidden;
	position: relative;
}

.cropwiseLogo {
	text-align: center;
	color: @cws-neutral-00;
	height: 60px;
    padding-top: 7px;
}
.cropwiseLogo img {
    max-height: 100%;
}

.sidebarSubmenuItem {
	padding-left: 16px;
}

.sidebarFooter {
	color: @cws-neutral-60;
	text-align: justify;
	position: absolute;
	bottom: 40px;
	font-size: 12px;
	padding-left: 24px;
}

.submenuName {
	font-size: 14px;
	position: static;
	height: 30px;
	line-height: 33px;
	margin: 0px 10px;
	align-items: center;
	overflow: hidden;
	text-overflow: ellipsis;
}

.submenuIcon {
	float: left;
	margin-right: 10px;
	margin-top: 5px;
	fill: @cws-neutral-60;
}

.submenuElementIcon {
	float: left;
	padding-right: 10px;
}

.sidebarContent {
	padding: 24;
	margin: 0;
	min-height: 280;
	text-align: center;
	background: @cws-neutral-00;
}

.sidebarLayoutContent {
	padding: 15px;
}

.triggerExpandButton {
	margin-top: 3px;
	fill: @cws-neutral-60;
}

.triggerCloseButton{
	float: right;
	margin-top: 3px;
	margin-right: 20px;
	fill: @cws-neutral-60;
}
.sibebarSyngentDigitalLogo {
    position: fixed;
    bottom: 0;
    height: 50px;
    z-index: 2;
    padding-left: 10px;
}
.syt-antd-menu-dark {
	background-color: @cws-neutral-90  !important;
}

.syt-antd-layout-sider-dark {
	background-color: @cws-neutral-90  !important;
}

.syt-antd-layout-sider-dark .syt-antd-layout-sider-trigger {
	background-color: @cws-neutral-100  !important;
}

.syt-antd-layout-sider-light .syt-antd-layout-sider-trigger {
	background-color: @cws-neutral-10  !important;
}

.syt-antd-menu:not(.syt-antd-menu-horizontal) .syt-antd-menu-item-selected {
	background-color: @cws-blue-10;
}

.syt-antd-menu:not(.syt-antd-menu-horizontal) .syt-antd-menu-item-selected::before {
	content: "";
  width: 4px;
  height: 32px;
  background: @cws-blue-60;
	position: absolute;
	top: 4px;
  left: 0px;
  border-radius: 0px 4px 4px 0px;
}

.syt-antd-menu-inline .syt-antd-menu-item::after {
	border-right: 0px !important;
}

.syt-antd-menu-item-selected {
	color: @cws-blue-60;
}

.syt-antd-menu-dark.syt-antd-menu-dark:not(.syt-antd-menu-horizontal) .syt-antd-menu-item-selected {
	background-color: @cws-neutral-100  !important;
}

.syt-antd-menu-dark.syt-antd-menu-dark:not(.syt-antd-menu-horizontal) .syt-antd-menu-item-selected::before {
	content: "";
  width: 4px;
  height: 32px;
  background: @cws-neutral-00;
	position: absolute;
	top: 4px;
  left: 0px;
  border-radius: 0px 4px 4px 0px;
}

.syt-antd-menu-dark .syt-antd-menu-inline.syt-antd-menu-sub {
	background: #232630;
}

ul.syt-antd-menu-inline-collapsed {
    width: 64px !important;
}
.syt-antd-menu-item {
    padding: 3px 20px !important

}

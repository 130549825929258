@import '~antd/lib/layout/style/index';

.withSelectMenu {
    margin-top: 50px;
}

.navbarLogo {
    float: left;
    padding-right: 40px;
    height: 48px;
    line-height: 50px;
}

.navbarLogoPlaceholder {
    background-color: @cws-neutral-00  !important;
}

.navbarUserArea {
    float: right;
    height: 48px;
    padding-right: 10px;
    line-height: 45px;
}

.navbarContextBar {
    height: 48px;
    width: 208px;
    padding-left: 8px;
    position: static;
    border-radius: 4px;

    &:hover {
        background-color: @cws-neutral-20;
    }
}

.submenuContextBar {
    height: 54px;
    width: 208px;
    padding-left: 8px;
    position: static;
    border-radius: 4px;
    float: left;

    &:hover {
        background-color: @cws-neutral-20;
    }
}

.navbarContextIcon {
    float: left;
    padding-right: 5px;
    align-items: center;
    line-height: 45px;
}

.submenuContextIcon {
    float: left;
    padding-right: 10px;
    align-items: center;
    line-height: 35px;
}

.contextBarTitle {
    position: static;
    width: 75px;
    height: 20px;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    align-items: center;
    color: @cws-neutral-60;
    margin: 4px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.contextBarSubTitle {
    font-size: 14px;
    position: static;
    width: 114px;
    height: 20px;
    line-height: 20px;
    align-items: center;
    color: @cws-neutral-100;
    margin: 0px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.contexBarArrow {
    float: right;
    height: 48px;
    line-height: 50px;
    align-items: center;
    margin-right: 4px;
}

.submenuArrow {
    float: right;
    height: 48px;
    line-height: 45px;
    align-items: center;
    margin-right: 4px;
}

.submenuTitle {
    font-size: 14px;
    position: static;
    height: 30px;
    line-height: 35px;
    margin: 0px 10px;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
}


.syt-antd-menu-item-group-title {
    color: @cws-neutral-100  !important;
    font-weight: 600;
}

.syt-antd-layout-header {
    height: 56px;
}

.syt-antd-menu-horizontal:not(.syt-antd-menu-dark) > .syt-antd-menu-item {
    margin: 0px;
}

.syt-antd-layout-header .syt-antd-menu {
    height: 56px;
}
.navbarDivider {
    height: 20px;
}

.navbarSeparator {
    position: relative;
    padding-left: 16px;
    padding-right: 16px;
    vertical-align: middle;
}
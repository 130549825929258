@import '~antd/lib/input/style/index';

.cwsInput {
  color: @cws-neutral-100;
  background-color: @cws-neutral-00;
  margin: 2px 0px;
  &:hover,
  &:focus {
    color: @cws-neutral-100;
    background-color: @cws-neutral-00;
    color: @cws-neutral-100;
    background-color: @cws-neutral-00;
  }
  &::placeholder {
    color: @cws-neutral-60;
  }
}

.inputLabel {
  height: 20px;
  left: 0px;
  right: 0px;
  top: 0px;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  display: flex;
  align-items: center;
  letter-spacing: -0.0075em;

  color: @cws-neutral-60;
}
.inputCaption {
  left: 0px;
  right: 0px;
  top: 64px;
  bottom: 0px;

  font-family: Noto Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.0075em;

  display: flex;
  align-items: center;
  letter-spacing: -0.0075em;

  color: @cws-neutral-60;
}

.defaultInput:extend(.cwsInput) {
  border: 1px solid @cws-neutral-30;
  svg {
    color: @cws-neutral-60;
  }
  &:hover {
    border: 1px solid @cws-blue-50;
  }
}
.defaultInputFocus:extend(.defaultInput) {
  border: 1px solid @cws-blue-50 !important;
  box-shadow: 0px 0px 0px 3px rgba(@cws-blue-50, 0.25) !important;
  svg {
    color: @cws-neutral-60;
  }
}
.defaultCaption:extend(.inputCaption) {
  color: @cws-neutral-60;
}
.contrastCaption:extend(.inputCaption) {
  color: @cws-neutral-70;
}

.successInput:extend(.cwsInput) {
  border: 1px solid @cws-neutral-30;
  svg {
    color: @cws-neutral-60;
  }
  &:hover {
    border: 1px solid @cws-blue-50;
  }
}
.successInputFocus:extend(.successInput) {
  border: 1px solid @cws-blue-50 !important;
  box-shadow: 0px 0px 0px 3px rgba(@cws-blue-50, 0.25) !important;
  svg {
    color: @cws-neutral-60;
  }
}
.warnInput:extend(.cwsInput) {
  border: 1px solid @cws-yellow-50;
  svg {
    color: @cws-neutral-60;
  }
  &:hover {
    border: 1px solid @cws-yellow-50;
  }
}
.warnInputFocus:extend(.warnInput) {
  border: 1px solid @cws-yellow-50 !important;
  box-shadow: 0px 0px 0px 3px rgba(@cws-yellow-40, 0.25) !important;
  svg {
    color: @cws-neutral-60;
  }
}
.warnCaption:extend(.inputCaption) {
  color: @cws-yellow-60;
}

.dangerInput:extend(.cwsInput) {
  border: 1px solid @cws-red-50;
  svg {
    color: @cws-neutral-60;
  }
  &:hover {
    border: 1px solid @cws-red-50;
  }
}
.dangerInputFocus:extend(.dangerInput) {
  border: 1px solid @cws-red-50 !important;
  box-shadow: 0px 0px 0px 3px rgba(@cws-red-50, 0.25) !important;
  svg {
    color: @cws-neutral-60;
  }
}
.dangerCaption:extend(.inputCaption) {
  color: @cws-red-60;
}
.disabled {
  color: @cws-neutral-60;
  background-color: @cws-neutral-20 !important;
}

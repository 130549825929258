@import '~antd/lib/typography/style/index';
@import '~antd/lib/tooltip/style/index.less';
// Override Ant Typography Variables
@font-family: 'noto-sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
@code-family:  'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
@text-color: @cws-neutral-100;
@text-color-secondary: @cws-neutral-60;
@text-color-inverse: @cws-neutral-00;
@icon-color-hover: @cws-neutral-50;
@heading-color: @cws-neutral-100;
@text-color-dark: @cws-neutral-00;
@text-color-secondary-dark: @cws-neutral-50;
@text-selection-bg: @primary-color;
@font-size-base: 14px;
@font-size-sm: @font-size-base - 2px;
@heading-1-size: ceil(@font-size-base * 2.57);
@heading-2-size: ceil(@font-size-base * 2.14);
@heading-3-size: ceil(@font-size-base * 1.71);
@heading-4-size: ceil(@font-size-base * 1.42);
@heading-5-size: ceil(@font-size-base * 1.14);
@line-height-base: 20px;
@border-radius-base: 4px;

h1.cwsTypography:extend(.syt-antd-typography) {
  height: 64px;
  left: 30.83%;
  right: 55.35%;
  top: calc(50% - 64px/2 + 554.5px);

  font-family: "noto-sans";
  font-style: normal;
  font-weight: normal;
  font-size: 56px;
  line-height: 64px;
  display: flex;
  letter-spacing: -0.0175em;

  color: @cws-neutral-100;

  strong {
    font-weight: normal;
  }
}

h2.cwsTypography:extend(.syt-antd-typography) {
  height: 54px;
  left: 30.83%;
  right: 57.78%;
  top: calc(50% - 54px/2 + 661.5px);

  font-family: "noto-sans";
  font-style: normal;
  font-weight: normal;
  font-size: 46px;
  line-height: 54px;
  display: flex;
  letter-spacing: -0.0175em;

  color: @cws-neutral-100;

  strong {
    font-weight: normal;
  }
}

h3.cwsTypography:extend(.syt-antd-typography) {
  height: 44px;
  left: 30.83%;
  right: 58.75%;
  top: calc(50% - 44px/2 + 750.5px);

  font-family: "noto-sans";
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 44px;
  display: flex;
  letter-spacing: -0.0175em;

  color: @cws-neutral-100;

  strong {
    font-family: "noto-sans";
    font-weight: 600;
  }
}

h4.cwsTypography:extend(.syt-antd-typography) {
  height: 38px;
  left: 30.83%;
  right: 60.42%;
  top: calc(50% - 38px/2 + 827.5px);

  font-family: "noto-sans";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  display: flex;
  letter-spacing: -0.0175em;

  color: @cws-neutral-100;

  strong {
    font-family: "noto-sans";
    font-weight: 600;
  }
}

span.cwsTypography:extend(.syt-antd-typography), div.cwsTypography:extend(.syt-antd-typography) {
  height: 24px;
  left: 30.83%;
  right: 64.44%;
  top: calc(50% - 24px/2 + 1165.5px);

  font-family: "noto-sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  letter-spacing: -0.0075em;

  color: @cws-neutral-100;

  strong {
    font-family: "noto-sans";
    font-weight: 600;
  }
}

.stories-row {
  margin-top: 30px;
}

.syt-antd-typography-copy {
  margin-left: 10px !important;
}